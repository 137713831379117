.if.tabs .if.next {
  background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 32 32%27%3E%3Cpath fill=%27none%27 stroke-miterlimit=%2710%27 d=%27M9 2l14 14L9 30%27 stroke-linecap=%27square%27 stroke-width=%271.5%27 stroke=%27%230054f0%27/%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: 50%;
  border-left-width: 1pt;
  border-left-style: solid;
  right: 0;
}
.if.tabs .if.prev {
  background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 32 32%27%3E%3Cpath fill=%27none%27 stroke-miterlimit=%2710%27 d=%27M23 30L9 16 23 2%27 stroke-linecap=%27square%27 stroke-width=%271.5%27 stroke=%27%230054f0%27/%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: 50%;
  border-right-width: 1pt;
  border-right-style: solid;
  left: 0;
}
