.if.backdrop {
  transition: all 0.3s ease-in;
  display: block;
  position: fixed;
  z-index: 97;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background-color: rgba(51, 30, 17, 0.4);
}
.if.backdrop.is-open {
  pointer-events: auto;
  opacity: 1;
  display: block;
}
.if.backdrop.is-open + .if.modal {
  display: flex;
}
.if.modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 16px 24px 2px rgba(51, 30, 17, 0.14), 0 6px 30px 5px rgba(51, 30, 17, 0.12),
    0 8px 10px -5px rgba(51, 30, 17, 0.2);
  transition: all 0.2s ease-in;
  background-color: #faf9f7;
  color: #331e11;
  z-index: 100;
  padding: 0;
  margin: 0;
  display: none;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  border-radius: 12px;
  overflow: hidden;
  max-width: 471px;
}

.if.modal .if.title {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  color: #331e11;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  position: relative;
  text-rendering: optimizeLegibility;
  font-size: 1.125rem;
  line-height: 1.75rem;
  letter-spacing: normal;
  font-variation-settings: 'wght' 126;
  font-family: If Sans, Arial, sans-serif;
  font-weight: 400;
  padding: 0 1.5rem;
  margin: 0;
  height: 4.5rem;
  background-color: #f1ece8;
  &:after {
    content: none;
  }
}

.if.axe.sr-only,
.if.hidden {
  clip: rect(1px, 1px, 1px, 1px);
  height: 0.0625rem;
  margin: 0;
  overflow: hidden;
  position: absolute;
  width: 0.0625rem;
}

.if.modal .if.content {
  background-color: unset;
  font-family: If Sans, Arial, sans-serif;
  font-weight: 400;
  display: flex;
  position: relative;
  padding: 1.25rem 1.5rem;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  p {
    margin: 0;
  }
}

.if.modal .if.footer {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  font-family: If Sans, Arial, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: normal;
  font-variation-settings: 'wght' 85;
  justify-content: center;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 40px 40px 40px;
  margin: 0;
}
#modal-title {
  background: var(--page-section-background-darker, #f1ece8);
}
#modal-title h2 {
  font-size: 24px;
}

.if.modal {
  input, select {
    width: 312px !important;
  }
  label {
    font-size: 16px;
  }
}

@media screen and (min-width: 45rem) {
  .if.modal .if.title {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

@media screen and (min-width: 75rem) {
  .if.modal .if.title {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 1.5rem;
    font-variation-settings: 'wght' 64;
  }
}

@media screen and (min-width: 45rem) {
  .if.modal .if.content {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}


@supports (display: grid) {
  .if.modal .if.content {
    font-weight: 400;
    font-variation-settings: 'wght' 78;
  }
}

@supports (display: grid) {
  .if.modal .if.title {
    font-weight: 400;
  }
}

@media screen and (min-width: 60rem) {
  .if.modal {
    width: 600px;
    max-height: 100vh;
    height: auto;
  }
}
