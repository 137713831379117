.iconWrapper {
  &:hover {
    cursor: pointer;
    background-color: rgba($color: #000000, $alpha: 0.1);
    border-radius: 5px;
  }
}
.if div.phone-input {
  margin-bottom: 0;
  label::after {
    background: none !important;
    content: '*';
    margin-left: 0.2em !important;
  }
}
