$div-margin-top-bottom-small: 0.7rem;
$flag-icon-width: 44px;
@font-face {
  font-family: 'If Sans Variable';
  src: url('https://static.design.if.eu/ids-font/IfSans-Variable.woff2') format('woff2'),
    url('https://static.design.if.eu/ids-font/IfSans-Variable.woff') format('woff'),
    url('https://static.design.if.eu/ids-font/IfSans-Variable.ttf') format('truetype');
}
.if {
  .icon {
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 16px 16px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    ids-icon {
      width: 16px;
      height: 16px;
    }
  }
  .ws-search {
    display: flex;
    flex-direction: column;
  }

  .form-row {
    display: flex;
    justify-content: space-between;
  }

  .form-field {
    width: 100%;
    padding: 0 1rem;
  }

  .ws-result-list {
    align-self: center;
    width: 100%;
  }

  .ws-result {
    display: grid;
    padding: $div-margin-top-bottom-small 0.5rem $div-margin-top-bottom-small 1.2rem;
    grid-template-columns: 45px auto;
    grid-template-rows: auto;
    margin: 0.6rem 0.3rem;
    grid-gap: 0;

    .ws-left {
      display: grid;
    }

    .ws-right {
      .ws-name {
        display: grid;
        grid-template-columns: auto 2em 2em;

        span {
          max-width: unset;
          word-wrap: break-word;
        }

        .copyButton,
        .workshop-link {
          display: inline-block;
          vertical-align: top;
          margin-bottom: 0;
          width: 2em;
          height: 2em;
          background-color: transparent;
          background-repeat: no-repeat;
          border: none;
          cursor: pointer;
          overflow: hidden;
          outline: none;
          background-position: 50%;
          background-size: 16px 16px;
        }
        .workshop-link {
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg class='nc-icon-wrapper' stroke-linecap='square' stroke-width='1.5' fill='none' stroke='%230054f0' stroke-miterlimit='10'%3E%3Cpath d='M25 19v11H2V7h8'/%3E%3Cpath data-cap='butt' data-color='color-2' d='M13 20v-2A10 10 0 0123 8h7' stroke-linecap='butt'/%3E%3Cpath data-color='color-2' d='M24 14l6-6-6-6'/%3E%3C/g%3E%3C/svg%3E");
        }
        .copyButton {
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg class='nc-icon-wrapper' stroke-linecap='square' stroke-width='1.5' fill='none' stroke='%23331e11' stroke-miterlimit='10'%3E%3Cpath d='M3 5h22v26H3zm5 7h12M8 18h12M8 24h4'/%3E%3Cpath data-color='color-2' d='M6 1h23v27'/%3E%3C/g%3E%3C/svg%3E");
        }
        .workshop-link::after {
          display: none !important;
        }
      }

      .ws-info {
        display: grid;
        grid-template-columns: 54% 22% 24%;

        .ws-distance {
          display: flex;
          flex-direction: column;
          text-align: center;
          margin: 0;

          i {
            display: block;
            align-self: center;
            width: 32px;
            height: 32px;
            float: left;
          }
        }
      }

      i {
        display: block;
        width: 30px;
        height: 30px;
      }

      .ws-services {
        display: flex;
        flex-direction: column;
        align-self: flex-end;
      }

      .ws-customer-services {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .workshop-letter-item {
          color: rgb(32, 32, 32);
          cursor: pointer;
        }
      }
    }
  }

  .if.button.filled {
    margin: 0 0;
    grid-column: 2;
    justify-self: center;
    min-width: 224px;
  }

  .workshop-refresh-wrapper {
    display: flex;
    padding-right: 1rem;
    justify-content: end;
    align-items: center;
    width: auto;

    .workshop-refresh:focus {
      outline: 0;
    }

    .workshop-refresh {
      width: 25px;
      height: 25px;
      background-color: transparent;
      background-repeat: no-repeat;
      border: none;
      cursor: pointer;
      overflow: hidden;
      outline: none;
    }
  }

  .if.icon.symbol.loop {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg class='nc-icon-wrapper' stroke-linecap='square' stroke-width='1.5' fill='none' stroke='%23331e11' stroke-miterlimit='10'%3E%3Cpath data-cap='butt' d='M8.004 26.264A12.957 12.957 0 0016 29c7.2 0 13-5.8 13-13S23.2 3 16 3c-5.2 0-9.9 3.2-11.9 7.7' stroke-linecap='butt'/%3E%3Ccircle data-color='color-2' cx='5' cy='23' r='4'/%3E%3Cpath d='M3.1 3l1 7.7 7.7-1'/%3E%3C/g%3E%3C/svg%3E");
    background-size: inherit;
    color: rgb(32, 32, 32);
  }

  .expandable {
    padding: 0.675rem 1rem;
    min-height: 4rem;
    align-items: center;
  }

  .form-actions {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: $div-margin-top-bottom-small 0 $div-margin-top-bottom-small;
    align-items: center;
  }

  .ws-form {
    align-self: center;
    display: flex;
    flex-direction: column;
    width: 100%;

    .if {
      .dropdown,
      .dropdown > select {
        width: 100%;
        height: 3rem;
      }
      .input-field {
        max-width: 100%;
      }
      .input-field ~ .input-help {
        max-width: max-content;
      }
    }

    #search-parameters-panel {
      margin: 0;

      .if.title {
        padding-left: 2rem;
      }

      .if.content {
        padding: 1.2rem 1.5rem 0;

        .form-row > .form-field {
          max-width: 50%;

          .form-group {
            margin-bottom: $div-margin-top-bottom-small;

            label {
              margin-bottom: 0.3rem;
            }

            select {
              padding: 0 0.9rem;
            }
          }
        }
      }
    }
  }

  .if.tabs {
    width: 100%;
  }
  .ws-tab-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;

    .next,
    .prev {
      background-size: 1.3rem 1.3rem;
      background-color: #faf9f7;
      position: absolute;
      top: 0;
      height: inherit;
      cursor: pointer;
      width: 3.5rem;
      z-index: 2;
      border: #e8e0d9;
      transition: background-size 0.2s cubic-bezier(0.4, 0, 0.2, 1);
      border-bottom: 2px solid #e8e0d9;
    }
    .ws-service-type-tabs {
      height: 100%;
      display: flex;
      overflow: hidden;
      li {
        list-style-type: none;
        border: 1px solid #e8e0d9;
        border-bottom-width: 2px;
        &:hover {
          background-color: #e8e0d9;
        }
        a {
          display: flex;
          padding: 0 0.8em;
          height: 100%;
          align-items: center;
          position: relative;
        }
      }
    }
  }

  .notification {
    p {
      max-width: none;
    }
  }
}

.ws-result-list {
  overflow-y: auto;
  overflow-x: hidden;
}

.ws-copy {
  display: inline-flex;
  align-items: stretch;
  align-self: flex-start;
  flex-direction: column;
  width: max-content;
  min-width: 0;
  flex-shrink: 0;
}

.ws-marker {
  display: flex;
  flex-direction: column;
}

.ws-address {
  display: inline-flex;
  align-items: stretch;
  align-self: flex-start;
  flex-direction: column;
  flex-shrink: 0;

  span {
    font-size: 1em;
    font-variation-settings: 'wght' 110;
    color: rgb(80, 80, 80);
    padding: 0;
    height: 100%;
    word-break: break-all;
  }
}

.ws-name,
.ws-distance {
  font-variation-settings: 'wght' 126;
}

.ws-error {
  margin: 2rem;
}

.map-container {
  width: 100vw;
  height: 100vh;
}

.ws-map {
  min-height: 100%;
}

.main-content {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.form-container {
  max-width: 512px;
  min-width: 485px;
  width: 37%;
  display: flex;
  flex-direction: column;
  max-height: 99vh;
}

.brand-logo {
  width: 36px;
  height: 36px;
}

header.if.minimal-header {
  height: 2.2rem;
}

.language-selector {
  button {
    &:focus {
      outline: 0;
      color: black;
    }
  }
  .if.menu {
    left: 0;
  }
}

.country-toggler {
  display: flex;
  align-self: center;
  flex-direction: column;

  .if.menu {
    position: absolute;
    top: 52px;
    min-width: 6rem;

    ul {
      padding: 0.5rem 0;
      display: flex;
      align-items: center;
      min-width: 6rem;

      li {
        margin: 0.6rem;
        min-width: $flag-icon-width;
        height: 25px;
      }
    }
  }
}

.flag-icon {
  width: $flag-icon-width;
  height: 25px;
  align-self: flex-end;
  cursor: pointer;
  -webkit-box-shadow: 0 5px 9px -5px rgba(0, 0, 0, 0.4);
  box-shadow: 0 5px 9px -5px rgba(0, 0, 0, 0.4);
}

.fadeout-popover-container {
  position: relative;
}

.fadeout-popover {
  z-index: 100;
  opacity: 1;
  position: absolute;

  visibility: hidden;
  text-align: center;
  top: 0.5rem;

  left: -0.5rem;
  width: min-content;
  height: min-content;
  bottom: 2rem;

  padding: 0.2rem 0.9rem;
  border-radius: 0.3ch;
  box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.27);
  background-color: #fffdf6;

  &.small-font {
    font-size: 0.93rem;
    font-variation-settings: 'wght' 100;
    font-weight: normal;
  }

  &::before {
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    right: auto;
    top: auto;
    bottom: 100%;
    border: 0.7rem solid transparent;
    border-bottom-color: #fffdf6;
    -webkit-filter: drop-shadow(0.5px 1px 0.5px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(0px 0.5px 0px rgba(0, 0, 0, 0.2));
  }

  &::after {
    border-color: transparent transparent #fffdf6;
    -webkit-filter: drop-shadow(0.5px 1px 0.5px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(0px 1.9px 0.5px rgba(0, 0, 0, 0.2));
  }

  &.workshop-clipboard-icon {
    left: -1.2rem;

    &::before {
      right: 1.9rem;
    }
  }
}

.fade-out {
  visibility: visible;

  -webkit-animation: fade_out 2s ease-in forwards;
  animation: fade_out 2s ease-in forwards;
}

@keyframes effect_dylan {
  40% {
    transform: scale(1.5, 1.5);
    opacity: 1;
  }
  80% {
    transform: scale(1.5, 1.5);
    opacity: 0;
  }
  100% {
    transform: scale(1.5, 1.5);
    opacity: 0;
  }
}

@keyframes fade_out {
  40% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
