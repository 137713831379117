.if.button>.if.loader {
  display: inline-block;
  flex-shrink: 0;
  flex-grow: 0;
  height: 2rem;
  width: 2rem;
  margin-right: 1rem;
}
.if.loader.dots.horizontal {
  circle:first-child {
    animation-delay: -.32s;
    transform-origin: 0.5rem 2rem;
  }
  circle:nth-child(2) {
    animation-delay: -.16s;
    transform-origin: 2rem 2rem;
  }
}
.if.loader.dots.horizontal circle {
  transform: scale(0);
  animation: ifAnimationLoaderHorizontal 1.15s ease-in-out infinite;
  transform-origin: 3.5rem 2rem;
}
@keyframes ifAnimationLoaderHorizontal {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
