.if.menu.is-open {
  transform-origin: 100% 0;
  display: flex;
  animation: openY .3s cubic-bezier(.4,0,.2,1);
}
.country-toggler .if.menu ul {
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  min-width: 6rem;
}
.if.menu>ul {
  list-style: none;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
  background-color: #faf9f7;
  z-index: 100;
  border-radius: 0.375rem;
    flex-grow: 1;
  padding: 1rem 0;
  min-width: 7rem;
  position: relative;
}
.if.menu.context.top:after, .if.menu.pinned.top:after {
  border-color: transparent transparent #faf9f7 !important;
  border-width: 10px!important;
  margin-left: -10px!important;
}
.if:after {
  box-sizing: border-box;
  line-height: 28px;
}
.if:before {
  box-sizing: border-box;
  line-height: 28px;
}
.if.menu.context.top:before, .if.menu.pinned.top:before {
  border-color: transparent transparent #e8e0d9!important;
  border-width: 11px!important;
  margin-left: -11px!important;
}
.if.menu.context.top:after, .if.menu.context.top:before, .if.menu.pinned.top:after, .if.menu.pinned.top:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
@keyframes openY {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: flex;
    opacity: 0;
    transform: scaleY(0);
  }
  50% {
    display: flex;
    transform: scaleY(1);
  }
  100% {
    opacity: 1;
    display: flex;
    transform: scaleY(1);
  }
}

