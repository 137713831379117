a.if {
  color: #331e11;
}
a.if[aria-selected="true"] {
  font-weight: bold;
  &::after {
    border-bottom: none;
    content: "";
    height: 2pt;
    width: 100%;
    z-index: 2;
    background-color: #0054f0;
    position: absolute;
    top: 100%;
    left: 0;
  }
}
