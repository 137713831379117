.if{
  .is-open>.title {
    background-color: #e8e0d9;
  }
  .title:hover {
    text-decoration: underline;
  }
}
.if.title {
  background-color: #f1ece8;
  align-items: center;
  cursor: pointer;
  display: flex;
  font-family: "If Sans Variable";
  font-size: 1.125rem;
  height: 4.5rem;
  letter-spacing: normal;
  position: relative;
  transition: all .2s cubic-bezier(.4,0,.2,1);
  width: 100%;
  padding: 0 5rem 0 1.5rem;
}
.if.title:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='none' stroke-miterlimit='10' d='M22 13l-6 6-6-6' stroke-linecap='square' stroke-width='1.5' stroke='%230054f0'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50%;
  content: "";
  right: 2.5rem;
  transition: all .2s cubic-bezier(.4,0,.2,1);
  position: absolute;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  height: 2rem;
  width: 2rem;
  background-size: 2rem 2rem;
}
.is-open>.title {
  font-family: "If Sans Variable";
  font-weight: bold;
  &::after {
    transform: translateY(-50%) rotate(180deg);
  }
}
.if.content {
  background-color: #f1ece8;
}
